
















































import { Component, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "../store";
@Component({})
export default class NewBoardCreatedAlert extends Vue {
  @State multiPlayerBoardCreated!: boolean;
  @State boardId!: string;
  @State gameGuest;
  @State updateHostStatus;

  @Watch("$store.state.gameGuest")
  onValueChanged() {
    store.commit("updateHostStatus", true);
    store.commit("updateMultiPlayerBoardCreationState", false);
    this.$router.push("/multiplayer");
  }
}
