

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Card } from "../../models";
import { State } from "vuex-class";
import store from "../../store";
import { FirebaseDataHandler } from "@/services/FirebaseDataHandler";
import CurrentPlayerTurnLabel from "./CurrentPlayerTurnLabel.vue";
import GameStartNotifier from "./GameStartNotifier.vue";
import PlayerScores from "./PlayerScores.vue";

@Component({
  components: { CurrentPlayerTurnLabel, PlayerScores, GameStartNotifier },
})
export default class MultiPlayer extends Vue {
  @State defaultImages!: boolean;
  @State boardContent!: any;
  @State user!: any;
  @State gameGuest!: any;
  @State requestToStartGame!: any;
  @State gameHost!: any;
  @State currentTurn!: any;
  @State boardId!: any;
  @State deleteBoard!: boolean;

  public cards: Card[] = [];
  public count = 0;
  public flippedSource = "https://cdn.vuetifyjs.com/images/cards/plane.jpg";
  public gameStarted = false;
  public gameOver = false;
  public drawGame = false;

  public matched = false;

  public guestScore = 0;
  public hostScore = 0;
  public myInterval: any;
  public winner = "";

  private audioToPlay = "https://www.fesliyanstudios.com/play-mp3/3518";

  created() {
    this.createBoard();
    new FirebaseDataHandler().getBoardContent(this.boardId);
  }

  createBoard() {
    this.cards = this.boardContent;
    console.log(this.cards);
    new FirebaseDataHandler().updateCards(this.cards, this.boardId);
  }

  shuffle() {
    console.log("in shuffle");
    for (let i = this.cards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.cards[i], this.cards[j]] = [this.cards[j], this.cards[i]];
    }
  }

  toggleCard(card: any) {
    if (card.isFlipped) {
      if (this.currentTurn == this.user["name"]) {
        this.count++;

        if (this.count === 1) {
          this.toggleFirstCard(card);
        } else if (this.count === 2) {
          this.toggleSecondCard(card);
        }
      }
    }
  }

  toggleFirstCard(card: Card) {
    card.isFlipped = !card.isFlipped;
    new FirebaseDataHandler().updateCards(this.cards, this.boardId);
  }

  toggleSecondCard(card: Card) {
    card.isFlipped = !card.isFlipped;
    this.matched = this.isPairCardFlipped(card);
    new FirebaseDataHandler().updateCards(this.cards, this.boardId);
    if (this.matched) {
      this.updateUserScore();
      this.audioToPlay = "https://www.fesliyanstudios.com/play-mp3/5744";
      new Audio(this.audioToPlay).play();
    } else {
      new Audio(this.audioToPlay).play();
    }

    setTimeout(() => {
      this.flipOpenCardsWithoutPairs();
      this.audioToPlay = "https://www.fesliyanstudios.com/play-mp3/3518";
    }, 3000);
  }

  isPairCardFlipped(card: Card) {
    let pairFlipped = false;
    this.cards.forEach((e) => {
      if (e.id == card.pairCardId) {
        if (e.isFlipped == false && e.isMatched == false) {
          e.isMatched = true;
          card.isMatched = true;
          pairFlipped = true;
          return;
        }
      }
    });
    return pairFlipped;
  }

  updateUserScore() {
    if (this.gameHost["name"] == this.currentTurn) {
      this.updateHostScore();
    } else {
      this.updateGuestScore();
    }
  }

  updateGuestScore() {
    this.gameGuest["score"] = this.gameGuest["score"] + 1;

    new FirebaseDataHandler().updateScore(
      "guestUser",
      this.gameGuest,
      this.boardId
    );
  }

  updateHostScore() {
    this.gameHost["score"] = this.gameHost["score"] + 1;

    new FirebaseDataHandler().updateScore(
      "hostUser",
      this.gameHost,
      this.boardId
    );
  }

  resetGuestScore() {
    this.gameGuest["score"] = 0;

    new FirebaseDataHandler().updateScore(
      "guestUser",
      this.gameGuest,
      this.boardId
    );
  }

  resetHostScore() {
    this.gameHost["score"] = 0;

    new FirebaseDataHandler().updateScore(
      "hostUser",
      this.gameHost,
      this.boardId
    );
  }

  flipOpenCardsWithoutPairs() {
    this.count = 0;
    this.cards.forEach((card) => (card.isFlipped = true));
    new FirebaseDataHandler().updateCards(this.cards, this.boardId);

    if (!this.matched) {
      if (this.gameHost["name"] == this.currentTurn) {
        new FirebaseDataHandler().updateUser(
          this.gameGuest["name"],
          this.boardId
        );
      } else {
        new FirebaseDataHandler().updateUser(
          this.gameHost["name"],
          this.boardId
        );
      }
    }
    this.matched = false;
  }

  findWinner() {
    const card = this.cards.find((card) => !card.isMatched);
    this.gameOver = !card;
    if (this.gameOver) {
      if (this.guestScore === this.hostScore) {
        this.drawGame = true;
      } else if (this.guestScore < this.hostScore) {
        this.winner = this.gameHost["name"];
      } else {
        this.winner = this.gameGuest["name"];
      }
      clearInterval(this.myInterval);
    }
  }

  @Watch("$store.state.boardContent")
  onValueChanged() {
    this.cards = this.boardContent;
    if (this.deleteBoard) {
      this.goBack();
    }
  }

  @Watch("$store.state.gameHost")
  onValueChanged1() {
    this.hostScore = (this.gameHost["score"] / 16) * 200;
    this.findWinner();
  }

  @Watch("$store.state.currentTurn")
  onValueChanged3() {
    console.log(this.currentTurn);
  }

  @Watch("$store.state.bothPlayersReady")
  onValueChanged4() {
    console.log("starting game");
    this.startGame();
  }

  @Watch("$store.state.gameGuest")
  onValueChanged2() {
    this.guestScore = (this.gameGuest["score"] / 16) * 200;
    this.findWinner();
  }

  @Watch("$store.state.deleteBoard")
  onValueChanged30() {
    store.commit("reset", true);
    this.$router.back();
  }

  restartGame() {
    this.gameOver = false;
    this.drawGame = false;
    this.cards.forEach((card) => {
      card.isFlipped = true;
      card.isMatched = false;
    });

    new FirebaseDataHandler().updateCards(this.cards, this.boardId);
    this.resetGuestScore();
    this.resetHostScore();

    this.createBoard();
    this.startGame();
  }

  goBack() {
    //store.commit("updateMultiPlayerBoardCreationState", false);

    //new FirebaseDataHandler().deleteBoard(this.boardId);
    store.commit("reset", true);
    this.$router.back();
  }

  onStartButtonClick() {
    if (this.gameHost["name"] == this.user["name"]) {
      new FirebaseDataHandler().hostRequestToStartGame(this.boardId);
    } else {
      new FirebaseDataHandler().guestRequestToStartGame(this.boardId);
    }
    //new FirebaseDataHandler().requestToStartGame(this.boardId);
  }

  startGame() {
    this.cards.forEach((card) => {
      card.isFlipped = true;
    });
    this.shuffle();
    new FirebaseDataHandler().updateCards(this.cards, this.boardId);
    this.gameStarted = true;
  }

  cardImage(card: Card) {
    const fileName = card.avatar;
    return require(`./../../assets/${fileName}`);
  }

  preventNav(event) {
    event.preventDefault();
    event.returnValue = "";
  }

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  }
}
