




































import { Component, Vue } from "vue-property-decorator";
import NewBoardCreatedAlert from "../../components/NewBoardCreatedAlert.vue";
import JoinExistingBoardAlert from "../../components/JoinExistingBoardAlert.vue";
import UploadImageFromDeviceDialog from "./UploadImageFromDeviceDialog.vue";

import store from "../../store";
import { State } from "vuex-class";
import { GameMode, User } from "../../models";
import { FirebaseDataHandler } from "@/services/FirebaseDataHandler";

@Component({
  components: {
    NewBoardCreatedAlert,
    JoinExistingBoardAlert,
    UploadImageFromDeviceDialog,
  },
})
export default class MultiPlayerSelection extends Vue {
  modal = false;
  @State user!: User;

  showSinglePlayerDropDown() {
    store.commit("updateGameMode", GameMode.MULTIPLAYER);
  }

  joinMultiPlayerBoard() {
    this.modal = !this.modal;
    store.commit("updateGuestStatus", true);
  }

  test(val: string) {
    console.log("val is ", val);
    new FirebaseDataHandler().joinBoard(val, this.user);
    //store.commit("setDefaultMode", true);

    this.$router.push("/multiplayer");
    this.modal = false;
  }
}
