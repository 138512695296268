










import { Component, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "../../store";
@Component({})
export default class CurrentPlayerTurnLabel extends Vue {
  @State currentTurn!: any;
}
