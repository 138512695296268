
















import { Component, Vue } from "vue-property-decorator";

import SinglePlayerSelection from "./SinglePlayerSelection.vue";
import MultiPlayerSelection from "./MultiPlayerSelection.vue";
import GameRuleInfoAlert from "./GameRuleInfoAlert.vue";
import store from "../../store";
@Component({
  components: {
    GameRuleInfoAlert,
    SinglePlayerSelection,
    MultiPlayerSelection,
  },
})
export default class GameSelection extends Vue {
  created() {
    store.commit("reset", true);
  }
}
