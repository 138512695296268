






























import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "../store";
@Component({})
export default class JoinExistingBoardAlert extends Vue {
  @Prop() dialog!: boolean;
  gameCode = "";

  @State joinMultiPlayerBoardAsGuest!: boolean;

  onclick() {
    this.$emit("clicked", this.gameCode);
  }
}
