


































import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "../../store";
@Component({})
export default class GameRuleInfoAlert extends Vue {
  dialog = true;
  val = false;
  @State showInfoScreen;

  created() {
    this.dialog = this.showInfoScreen;
  }
  cancel() {
    this.dialog = false;
  }

  toggle(val) {
    //
  }

  viewGameRules() {
    localStorage.setItem("mem-user-user-info", JSON.stringify(!this.val));

    store.commit("updateInfoScreenStatus", !this.val);
    this.$router.push("/about");
  }
}
