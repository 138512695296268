




































import { Component, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "../../store";
@Component({})
export default class GameStartNotifier extends Vue {
  @State requestToStartGame!: any;
  @State user!: any;
  @State gameHost!: any;
  @State gameGuest!: any;
  @State hostRequestToStartGame!: boolean;
  @State guestRequestToStartGame!: boolean;
  showDialog = false;
  progressValue = 20;
  waiting = false;

  //@Watch("$store.state.requestToStartGame")
  onValueChanged4() {
    this.showDialog = true;
    const interval = setInterval(() => {
      this.progressValue = this.progressValue + 20;
      if (this.progressValue === 100) {
        clearInterval(interval);
        this.showDialog = false;
        store.commit("updateBothPlayersReady", true);
      }
    }, 1000);
  }

  @Watch("$store.state.hostRequestToStartGame")
  onValueChanged5() {
    if (this.gameHost["name"] == this.user["name"]) {
      this.waiting = !this.waiting;
    }

    if (this.guestRequestToStartGame) {
      this.waiting = false;
      this.onValueChanged4();
    }
  }

  @Watch("$store.state.guestRequestToStartGame")
  onValueChanged6() {
    if (this.gameGuest["name"] == this.user["name"]) {
      this.waiting = !this.waiting;
    }
    if (this.hostRequestToStartGame) {
      this.waiting = false;
      this.onValueChanged4();
    }
  }
}
