import { render, staticRenderFns } from "./GameStartNotifier.vue?vue&type=template&id=8f39b0b8&"
import script from "./GameStartNotifier.vue?vue&type=script&lang=ts&"
export * from "./GameStartNotifier.vue?vue&type=script&lang=ts&"
import style0 from "./GameStartNotifier.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDialog,VListItem,VListItemContent,VListItemTitle,VProgressCircular,VProgressLinear,VRow})
