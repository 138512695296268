


























import { Component, Vue } from "vue-property-decorator";
import NewBoardCreatedAlert from "../../components/NewBoardCreatedAlert.vue";
import JoinExistingBoardAlert from "../../components/JoinExistingBoardAlert.vue";
import CardCategoryDialog from "./CardCategoryDialog.vue";
import UploadImageFromDeviceDialog from "./UploadImageFromDeviceDialog.vue";
import store from "../../store";
import { State } from "vuex-class";
import { GameMode } from "@/models";

@Component({
  components: {
    NewBoardCreatedAlert,
    JoinExistingBoardAlert,
    UploadImageFromDeviceDialog,
    CardCategoryDialog,
  },
})
export default class SinglePlayerSelection extends Vue {
  showSinglePlayerDropDown() {
    store.commit("updateGameMode", GameMode.SINGLEPLAYER);
  }
}
