



































































import { Component, Vue } from "vue-property-decorator";
import store from "../store";
@Component({})
export default class SignUp extends Vue {
  drawer = false;

  items = [
    { title: "Home", icon: "mdi-view-dashboard", route: "home" },
    { title: "About", icon: "mdi-information", route: "about" },
  ];

  onSignOutClick() {
    store.commit("updateMultiPlayerBoardCreationState", false);
    this.$router.push("/");
  }

  goTo(route) {
    this.drawer = false;
    store.commit("updateMultiPlayerBoardCreationState", false);
    this.$router.push("/" + route);
  }
}
