





































































































import { Component, Vue } from "vue-property-decorator";
import { Card } from "../models";
import { State } from "vuex-class";
import store from "../store";
@Component({})
export default class SinglePlayer extends Vue {
  @State stored!: boolean;
  @State images;
  @State defaultImages!: boolean;
  @State imageCategory!: string;
  public cards: Card[] = [];
  public count = 0;
  flippedSource = "https://cdn.vuetifyjs.com/images/cards/plane.jpg";
  gameStarted = false;
  matchedCardCount = 0;
  public totalTurns = 0;
  public gameOver = false;
  private audioToPlay = "https://www.fesliyanstudios.com/play-mp3/3518";

  myInterval: any;
  hours = 0;
  minutes = 0;
  seconds = 0;
  timeToRender = "00:00";
  progressValue = 0;
  public cardOriginal: Card | undefined = {
    id: 0,
    pairCardId: 0,
    isMatched: false,
    isFlipped: false,
    avatar: "",
  };

  public cardPair: Card | undefined = {
    id: 0,
    pairCardId: 0,
    isMatched: false,
    isFlipped: false,
    avatar: "",
  };

  toggleCard(card: any) {
    if (card.isFlipped) {
      this.count++;

      if (this.count < 2) {
        card.isFlipped = !card.isFlipped;
        this.cardOriginal = this.cards.find((e) => e.id === card.id);
        this.cardOriginal!.isFlipped = false;
      } else if (this.count == 2) {
        console.log("flipped second card");
        card.isFlipped = !card.isFlipped;

        this.cardPair = this.cards.find((e) => e.id === card.id);
        if (this.cardPair && this.cardOriginal) {
          this.cardPair.isFlipped = false;
          console.log(this.cardPair);
          if (
            this.cardPair.id === this.cardOriginal.pairCardId &&
            this.cardOriginal.isFlipped === this.cardPair.isFlipped
          ) {
            console.log("here");
            this.cardPair.isMatched = true;
            this.cardOriginal.isMatched = true;
            this.matchedCardCount++;
            this.progressValue =
              (this.matchedCardCount / this.cards.length) * 200;
            card.isMatched = true;
            this.audioToPlay = "https://www.fesliyanstudios.com/play-mp3/5744";
            new Audio(this.audioToPlay).play();
          } else {
            new Audio(this.audioToPlay).play();
          }

          setTimeout(() => {
            this.count = 0;
            this.totalTurns++;
            this.audioToPlay = "https://www.fesliyanstudios.com/play-mp3/3518";
            this.cards.forEach((card) => (card.isFlipped = true));
            this.findWinner();
          }, 1000);
        }
      }
    }
  }

  findWinner() {
    const card = this.cards.find((card) => !card.isMatched);
    console.log(!!card);
    this.gameOver = !card;
    if (this.gameOver) {
      clearInterval(this.myInterval);
    }
  }
  created() {
    this.createBoard();
  }

  createBoard() {
    const totalCardCount = 16;
    this.cards = [];
    if (this.defaultImages) {
      for (let i = 0; i < totalCardCount; i++) {
        const card: Card = {
          id: i,
          pairCardId: totalCardCount - i - 1,
          isMatched: false,
          isFlipped: false,
          avatar:
            i <= 7
              ? this.imageCategory + "/img_" + (i + 1) + ".jpeg"
              : this.imageCategory + "/img_" + (totalCardCount - i) + ".jpeg",
          flex: 3,
        };
        this.cards.push(card);
      }
    } else {
      this.cards = this.images;
    }

    this.shuffle();
  }

  shuffle() {
    for (let i = this.cards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.cards[i], this.cards[j]] = [this.cards[j], this.cards[i]];
    }
  }

  restartGame() {
    this.gameOver = false;
    this.matchedCardCount = 0;
    this.progressValue = 0;
    this.totalTurns = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.timeToRender = "00:00";
    this.createBoard();
    this.startGame();
  }

  goBack() {
    store.commit("reset", true);
    this.$router.back();
  }

  startGame() {
    this.cards.forEach((card) => {
      card.isFlipped = true;
      card.isMatched = false;
    });
    this.gameStarted = true;
    this.myInterval = setInterval(() => {
      if (this.seconds == 59) {
        this.seconds = 0;
        this.minutes = this.minutes + 1;
      } else {
        this.seconds = this.seconds + 1;
      }
      this.timeToRender =
        ("0" + this.minutes).slice(-2) + ":" + ("0" + this.seconds).slice(-2);
    }, 1000);
    this.shuffle();
  }

  cardImage(card: Card) {
    const fileName = card.avatar;
    return require(`./../assets/${fileName}`);
  }

  preventNav(event) {
    event.preventDefault();
    event.returnValue = "";
  }

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  }
}
