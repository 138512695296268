









































import { Component, Vue } from "vue-property-decorator";
import * as firebase from "firebase";
import { FirebaseDataHandler } from "../services/FirebaseDataHandler";
import store from "../store";
@Component({})
export default class SignUp extends Vue {
  valid = true;
  firstname = "";
  nameRules = [
    (v) => !!v || "Name is required",
    (v) => v.length <= 10 || "Name must be less than 10 characters",
  ];
  onButtonClick() {
    new FirebaseDataHandler().signInAnonymously(this.firstname);
    this.$router.push("/home");
    store.commit("setNavbarState", false);
  }

  created() {
    store.commit("setNavbarState", true);
  }
}
